var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', [_c('h3', {
    staticClass: "page-title mb-7 font-weight-bold"
  }, [_vm._v("色設定")]), _c('Table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.services,
      "total-records": _vm.services.length,
      "items-per-page": 25,
      "loading": _vm.loading,
      "footer": null
    },
    scopedSlots: _vm._u([{
      key: "item.background_color",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "color": item.background_color,
                  "dark": ""
                }
              }, on))];
            }
          }], null, true)
        }, [_c('v-color-picker', {
          staticClass: "mx-auto",
          attrs: {
            "show-swatches": "",
            "hide-canvas": "",
            "hide-inputs": ""
          },
          on: {
            "input": function input($event) {
              return _vm.onColorChanged($event, 'background_color', item.id);
            }
          },
          model: {
            value: item.background_color,
            callback: function callback($$v) {
              _vm.$set(item, "background_color", $$v);
            },
            expression: "item.background_color"
          }
        })], 1)];
      }
    }, {
      key: "item.font_color",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "color": item.font_color,
                  "dark": ""
                }
              }, on))];
            }
          }], null, true)
        }, [_c('v-color-picker', {
          staticClass: "mx-auto",
          attrs: {
            "show-swatches": "",
            "hide-canvas": "",
            "hide-inputs": ""
          },
          on: {
            "input": function input($event) {
              return _vm.onColorChanged($event, 'font_color', item.id);
            }
          },
          model: {
            value: item.font_color,
            callback: function callback($$v) {
              _vm.$set(item, "font_color", $$v);
            },
            expression: "item.font_color"
          }
        })], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }