<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container>
      <h3 class="page-title mb-7 font-weight-bold">色設定</h3>
      <Table
        :headers="headers"
        :items="services"
        :total-records="services.length"
        :items-per-page="25"
        :loading="loading"
        :footer="null"
      >
        <template v-slot:item.background_color="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn :color="item.background_color" dark v-on="on"></v-btn>
            </template>
            <v-color-picker
              show-swatches
              hide-canvas
              hide-inputs
              v-model="item.background_color"
              class="mx-auto"
              @input="onColorChanged($event, 'background_color', item.id)"
            ></v-color-picker>
          </v-menu>
        </template>
        <template v-slot:item.font_color="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn :color="item.font_color" dark v-on="on"></v-btn>
            </template>
            <v-color-picker
              show-swatches
              hide-canvas
              hide-inputs
              v-model="item.font_color"
              class="mx-auto"
              @input="onColorChanged($event, 'font_color', item.id)"
            ></v-color-picker>
          </v-menu>
        </template>
      </Table>
    </v-container>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { debounce } from 'lodash'
export default {
  name: 'Color',
  components: { Table },
  data() {
    return {
      headers: [
        { text: 'サービスタイプ', align: 'center', value: 'name' },
        {
          text: '背景色',
          align: 'center',
          value: 'background_color',
          sortable: false
        },
        {
          text: '文字色',
          align: 'center',
          value: 'font_color',
          sortable: false
        }
      ],
      loading: false
    }
  },
  computed: {
    services() {
      return this.$store.getters.allServices
    },
    servicePagination() {
      return this.$store.getters.servicePagination
    }
  },

  created() {
    this.getDataFromApi()
  },
  methods: {
    async getDataFromApi() {
      this.loading = true
      await this.$store.dispatch('SERVICE_GET_ALL')
      this.loading = false
    },
    onColorChanged: debounce(async function(color, mode, id) {
      this.loading = true
      let params = {}
      params.color = color
      params.field = mode
      params.server_type_id = id
      await this.$store.dispatch('SERVICE_CHANGE_BG_COLOR', params)
      this.loading = false
    }, 500)
  }
}
</script>
